/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-string-refs */
import React from 'react';
// import heroThumbOne from '../../assets/images/hero-thumb-9_310.png';
import UserCounter from './UserCounter';
import './userCounter.css';

function HeroHomeOne({ className }) {
  return (
    <>
      <section className={`appie-hero-area bg-pattern  ${className || ''}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="appie-hero-content">
                <h1 className="appie-title lilita-one-regular">
                  Secure Scholarships, Fellowships and Study Abroad
                  Opportunities Fast, Efficient and Independently
                </h1>
                <p className="text-center text-muted lh-lg">
                  <i
                    className="fa fa-search mr-2 primary-color"
                    aria-hidden="true"
                  ></i>
                  Automatically find{' '}
                  <span className="bold-text-orange">
                    fully funded opportunities
                  </span>{' '}
                  even those hidden in social media and <br /> list of{' '}
                  <span className="bold-text-purple">
                    potential supervisors
                  </span>{' '}
                  with recent funding without endless scrolling in webpages
                  <br />
                  <span className="mt-2">
                    <i
                      className="fa fa-envelope mr-2 primary-color"
                      aria-hidden="true"
                    ></i>
                    Generate personalized email matches supervisors’ profile{' '}
                    <br /> and CV/SOP tailored to position requirements
                  </span>
                </p>
                <div className="d-flex justify-content-center mt-30 mb-40">
                  <a
                    className="main-btn  mx-auto"
                    href="https://dashboard.applykite.com/"
                  >
                    Sign up and Try it For Free
                  </a>
                </div>
                <UserCounter />

                {/* <p className="mt-30 text-center ">
                  Trusted by +25,000 postgraduate students and researchers in
                  Telegram Community
                </p>
                <ul className="d-sm-flex justify-content-center mt-20">
                  <li>
                    <a
                      className="main-btn ml-sm-30"
                      href="https://t.me/ApplyKiteBot"
                    >
                      <i className="fab fa-telegram" /> Join Telegram Bot
                    </a>
                  </li>
                  <li>
                    <a className="item-2" href="https://t.me/ApplyIR2UK">
                      <i className="fab fa-telegram" />
                      Join Telegram Channel
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroHomeOne;
